import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { verificationCodeDto } from '../models/dtos.model';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {
  public showLogin = true;
  public showSignUp1 = true;
  public showSignUp2 = false;
  public showResetPwd1 = false;
  public showResetPwd2 = false;
  public showResetPwd3 = false;
  public showLoginstep = true;
  public showsignupstep = false;
  public showPricingTable = false;
  public showLogin2 = false;
  public showIncorrectCode = false;
  public loginResult : any;
  public loginFailed : boolean = false;
  public emailforreset="";
  public resetcode="";
  public intendedUserId=null;
  public passwordForRest="";
  public confirmPasswordForReset="";
  public showpassword = false;
  public fieldType = "password";
  public iconType="icon-view";
  public siteKey = "6Lez6NMZAAAAAMD7ZBqIH-0Zj4fHvqNkwFy7th83";
  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "en";
  public type: "image" | "audio";
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public showpasswordpassword = false;
  public fieldTypePassword = "password";
  public iconTypePassword="icon-view";
  public current_year = new Date().getFullYear();
  public showpasswordpasswordconfirm = false;
  public fieldTypePasswordC = "password";
  public iconTypeC="icon-view";

  public loginFormGroup : FormGroup;
  constructor(
    private formBuilder :FormBuilder,
    private userService : UserService,
    private router : Router,
    private authService:AuthService,
    private toasterService: ToastrService,
    private dbService: NgxIndexedDBService
    ) {

  }

  ngOnInit(): void {
    this.dbService.clear('Screenings').subscribe(res=>{
    });
    this.dbService.clear('Folders').subscribe(res=>{
    });
    this.loginFormGroup = this.formBuilder.group({
      EmailAddress : ["",Validators.required],
      Password : ["",Validators.required]
    });
  }

public showResetPasswordSteps(step)
{
  if(step == 1){
    this.showLogin = false;
    this.showResetPwd1 = true;
    this.showResetPwd2 = false;
    this.showResetPwd3 = false;
  }
  else if(step == 2){
    this.authService.findAccount(this.emailforreset).subscribe(ans=>{
      localStorage.setItem("resetToken",ans.resetToken);
      this.showLogin = false;
      this.showResetPwd1 = false;
      this.showResetPwd2 = true;
      this.showResetPwd3 = false;
    },err=>{
      alert(err.error);
    })
  }
  else if(step == 3){
    this.authService.checkResetCode({code:this.resetcode,email:this.emailforreset}).subscribe(ans=>{
      localStorage.setItem("resetToken",ans.resetToken);
      this.intendedUserId=ans.id
      this.showLogin = false;
      this.showResetPwd1 = false;
      this.showResetPwd2 = false;
      this.showResetPwd3 = true;
    },err=>{
      alert(err.error);
    })
  }
}

public sendcode(){
  this.authService.findAccount(this.loginFormGroup.value.EmailAddress).subscribe(ans=>{
    this.toasterService.success("We sent you a new verification code", "Success");
  })
}
public handleSuccess($event)
{
  this.captchaSuccess = true;
}
public resetPassword(){
  this.userService.updateUserPassword(this.intendedUserId,{
    newPassword:this.passwordForRest,
    confirmPassword:this.confirmPasswordForReset
  }).subscribe(ans=>{
    this.showLoginSignUpSteps(1);
  },err=>{
  })
}

public showSignUpSteps(step)
{
  if(step == 2){
    this.showSignUp1 = false;
    this.showSignUp2 = true;
  }
  if(step == 3){
    this.showLogin2 = true;
    this.showSignUp1 = false;
    this.showSignUp2 = false;
  }

}

public showLoginSignUpSteps(step)
{
  if(step == 1){
    this.showLoginstep = true;
    this.showsignupstep  = false;
    this.showLogin = true;
    this.showLogin2 = false;
    this.showSignUp1 = false;
    this.showSignUp2 = false;
    this.showResetPwd1 = false;
    this.showResetPwd2 = false;
    this.showResetPwd3 = false;
    }else if( step == 2)
    {
    this.showSignUp1 = true;
    this.showsignupstep  = true;
    this.showLoginstep = false;
    this.showResetPwd1 = false;
    this.showResetPwd2 = false;
    this.showResetPwd3 = false;
    }

}
public navigateFromLogin(): void {
  localStorage.setItem("access_token", this.loginResult.token);
  localStorage.setItem("thread", "");
  this.router.navigate([this.loginResult.redirectTo]);
}

  public loginUser():void{
    this.userService.loginUser(this.loginFormGroup.value).subscribe(res =>{
      this.loginResult = res;
        if (res.verified == true) {
          this.navigateFromLogin();
        } else {
          this.showLogin2 = !res.verified;
          this.showLogin = false;
        }
    },
    (error) => {
      this.loginFailed = true;
    }
    );

  }

  public verifyCode(code): void {
    let codeDto= {
      userId: this.loginResult.userId,
      verificationCode: Number(code.value),
    };
    this.userService.verifyCode(codeDto, this.loginResult).subscribe((res) => {
      if (res.result == "success") {
        this.navigateFromLogin();
      }
      else{
        this.showIncorrectCode=true;
      }
    });
  }

  public showPasswordText(input)
  {
    if(input == 1)
    {
    if (this.showpassword == false){
      this.showpassword = true;
      this.fieldType="text";
      this.iconType="icon-hide";
    }
    else{
      this.showpassword = false;
      this.fieldType="password";
      this.iconType="icon-view";
    }
    }
    if(input == 2)
    {
    if (this.showpasswordpassword == false){
      this.showpasswordpassword = true;
      this.fieldTypePassword="text";
      this.iconTypePassword="icon-hide";
    }
    else{
      this.showpasswordpassword = false;
      this.fieldTypePassword="password";
      this.iconTypePassword="icon-view";
    }
    }
    if(input == 3)
    {
    if (this.showpasswordpasswordconfirm == false){
      this.showpasswordpasswordconfirm = true;
      this.fieldTypePasswordC="text";
      this.iconTypeC="icon-hide";
    }
    else{
      this.showpasswordpasswordconfirm = false;
      this.fieldTypePasswordC="password";
      this.iconTypeC="icon-view";
    }
    }

  }

}
