import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  public currentUser: User = new User();
  public showResetView: boolean = false;
  public showImageView: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.currentUserData();
  }

  public currentUserData(): void {
    this.userService.getCurrentUser().subscribe(user=>{
      this.userService
      .getUserById(Number(user.userId))
      .subscribe((res) => {
        this.currentUser = res;
        console.log(this.currentUser);
        
        if (this.currentUser.profilePicture == "") {
          this.currentUser.profilePicture = "../../../assets/loginbg.jpg";
        }
      },
      error => {
        this.authService.logout();
      }
      );
    });
  }

  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
    this.showImageView = false;
  }
  public showImageModal(isShow: boolean) {
    this.showImageView = isShow;
    this.showResetView = false;
  }

  public logOut(): void {
    this.authService.logout();
  }
}
