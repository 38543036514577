<div class="reflow-menu">
    <ul class="nav navbar-nav">
        <li class="active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <img src="{{ currentUser?.profilePicture }}" alt="" class="profile-picture" />
            <label class="sidebar-username" id="user">Hi {{ currentUser.fullName }}</label
      >
      <em class="fa fa-angle-down expend-icon" id="expend"></em>
    </li>
    <li class="collapse" id="collapseExample" (click)="showResetModal(true)">
      <em class="fa fa-lock"></em>
      <label>Reset password</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="showImageModal(true)">
            <em class="fa fa-picture-o"></em>
            <label>Change profile image</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="logOut()">
            <em class="fa fa-sign-out"></em>
            <label>Log out</label>
        </li>
        <hr class="collapse" id="collapseExample" style="
        background-color: #284e74;
        height: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      " />
    </ul>

    <ul class="nav navbar-nav">
        <li routerLink="validation">
            <em class="fa fa-envelope-open-o"></em>
            <label>Validation Dashboard</label>
        </li>
        <li routerLink="databases">
            <em class="fa fa-envelope-open-o"></em>
            <label>Edit MasterSheet</label>
        </li>
    </ul>
</div>