import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
     .big-modal  {
      background-color: #2B4F73;
      height:100%;
      width:100%;
    }
    .modal-content{
      border:0;
      height:100%;
      width:100%;
      position: relative;
    }
  `]
})
export class LogoutModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal, public userService:UserService, private authService: AuthService, ) { }

  ngOnInit(): void {
    this.userService
    .getUserById(Number(localStorage.getItem("user_id")))
    .subscribe(
      result => {
       },
      error => {
        this.authService.logout();
      }
    );
  }

  public logout():void{
    this.passEntry.emit("logout");
    this.activeModal.close();
  }

  public close():void{
    this.activeModal.close();
  }

}
