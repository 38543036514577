import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LogoutModalComponent } from "../shared/logout-modal/logout-modal.component";
import { AuthService } from "./auth.service";
import { HelperService } from "./helper.service";

const MINUTES_UNITL_AUTO_LOGOUT = 120; // in mins
const CHECK_INTERVAL = 5000; // in ms
const STORE_KEY = "lastAction";
@Injectable({
  providedIn: "root",
})
export class AutoLogoutService {
  val: any;
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(
    private router: Router,
    private helperService:HelperService,
    private modalService:NgbModal,
    private authService:AuthService
    ) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener("click", () => this.reset());
    document.body.addEventListener("mouseover", () => this.reset());
    document.body.addEventListener("mouseout", () => this.reset());
    document.body.addEventListener("keydown", () => this.reset());
    document.body.addEventListener("keyup", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
    window.addEventListener("storage", () => this.storageEvt());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 240 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      //localStorage.clear();
      const modalRef = this.modalService.open(LogoutModalComponent, {windowClass:'big-modal'});
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        if(receivedEntry=="logout")
        {
          this.authService.logout();
        } 
      });
      
    }
  }
  storageEvt() {
    this.val = localStorage.getItem(STORE_KEY);
  }
}
