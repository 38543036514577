import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AdminSpaceComponent } from "./admin-space/admin-space.component";
import { AuthGuard } from "./auth/auth.guard";
import { CompaniesOverviewComponent } from "./admin-space/companies-overview/companies-overview.component";
import { ShowCompanyComponent } from "./admin-space/show-company/show-company.component";
import { ExternalCompaniesOverviewComponent } from "./admin-space/external-companies-overview/external-companies-overview.component";
import { BestPracticeComponent } from "./best-practice/best-practice.component";
import { ServiceProviderSpaceComponent } from "./service-provider-space/service-provider-space.component";
import { VesselOperatorSpaceComponent } from "./vessel-operator-space/vessel-operator-space.component";
import { ComponentOverviewComponent } from "./service-provider-space/component-overview/component-overview.component";
import { WarehouseOverviewComponent } from "./service-provider-space/warehouse-overview/warehouse-overview.component";
import { VesselsOverviewComponent } from "./vessel-operator-space/vessels-overview/vessels-overview.component";
import { ShowVesselComponent } from "./vessel-operator-space/show-vessel/show-vessel.component";
import { ShowLocationComponent } from "./vessel-operator-space/show-vessel/show-location/show-location.component";
import { UnassignedComponentsOverviewComponent } from "./vessel-operator-space/unassigned-components-overview/unassigned-components-overview.component";
import { PrintPdfComponent } from "./print-pdf/print-pdf.component";
import { ExchangePanelComponent } from "./service-provider-space/exchange-panel/exchange-panel.component";
import { AdminGuard } from "./auth/admin.guard";
import { SupplierGuard } from "./auth/supplier.guard";
import { VesselOwnerGuard } from "./auth/vessel-owner.guard";
import { Co2LightComponent } from "./service-provider-space/co2-light/co2-light.component";
import { LightResultComponent } from "./service-provider-space/co2-light/light-result/light-result.component";
import { MobileOverviewComponent } from './mobile-overview/mobile-overview.component';
import { ScreeningOverviewComponent } from "./service-provider-space/screening-overview/screening-overview.component";
import { EditScreeningComponent } from "./service-provider-space/screening-overview/edit-screening/edit-screening.component";
import { PlatformUpdatesComponent } from "./admin-space/platform-updates/platform-updates.component";
import { HomeAdminComponent } from "./service-provider-space/home-admin/home-admin.component";
import { CompareFunctionComponent } from './service-provider-space/screening-overview/compare-function/compare-function.component';
import { SuccessSignupComponent } from "./login/success-signup/success-signup.component";
import { OneTimeRedirectionComponent } from "./service-provider-space/screening-overview/one-time-redirection/one-time-redirection.component";
import { DashboardComponent } from "./admin-space/dashboard/dashboard.component";
import { CompareProjectsComponent } from "./service-provider-space/compare-projects/compare-projects.component";
import { AcademyAdminComponent } from "./admin-space/academy-admin/academy-admin.component";
import { ReflowAcademyComponent } from "./service-provider-space/reflow-academy/reflow-academy.component";
import { MasterSheetComponent } from "./admin-space/master-sheet/master-sheet.component";
import { DatabaseOverviewComponent } from "./admin-space/master-sheet/database-overview/database-overview.component";
import { GroupsOverviewComponent } from "./admin-space/master-sheet/groups-overview/groups-overview.component";
import { SubGroupsOverviewComponent } from "./admin-space/master-sheet/sub-groups-overview/sub-groups-overview.component";
import { EndGroupsOverviewComponent } from "./admin-space/master-sheet/end-groups-overview/end-groups-overview.component";
import { ProcessesOverviewComponent } from "./admin-space/master-sheet/processes-overview/processes-overview.component";
import { NewLoginComponent } from "./new-login/new-login.component";
import { SalesSpaceComponent } from "./sales-space/sales-space.component";
import { SalesOverviewComponent } from "./sales-space/sales-overview/sales-overview.component";
import { ValidationDashboardComponent } from "./admin-space/validation-dashboard/validation-dashboard.component";
import { SalesGuard } from "./auth/sales.guard";
import { ValidationReviewComponent } from "./admin-space/validation-dashboard/validation-review/validation-review.component";
import { ShareOverviewComponent } from "./service-provider-space/share-overview/share-overview.component";
import { EngineersSpaceComponent } from "./engineers-space/engineers-space.component";
import { EngineersGuard } from "./auth/engineers.guard";
import { PublicAssessmentComponent } from "./service-provider-space/share-overview/public-assessment/public-assessment.component";
import { CompanyAssesstmentOverviewComponent } from "./service-provider-space/company-assesstment-overview/company-assesstment-overview.component";
import { CompanyAssessmentDataComponent } from "./admin-space/company-assessment-data/company-assessment-data.component";
import { DatabasesComponent } from "./admin-space/company-assessment-data/databases/databases.component";
import { CompanyAssessmentGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-groups/company-assessment-groups.component";
import { CompanyAssessmentSubGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-sub-groups/company-assessment-sub-groups.component";
import { CompanyAssessmentEndGroupsComponent } from "./admin-space/company-assessment-data/company-assessment-end-groups/company-assessment-end-groups.component";
import { CompanyAssessmentProcessComponent } from "./admin-space/company-assessment-data/company-assessment-process/company-assessment-process.component";
import { AssessmentDashboardComponent } from "./service-provider-space/company-assesstment-overview/assessment-dashboard/assessment-dashboard.component";
import { CalculationStepperComponent } from "./service-provider-space/company-assesstment-overview/calculation-stepper/calculation-stepper.component";



const routes: Routes = [
  {
    path: "",
    component: NewLoginComponent,
  },
  {
    path: "login",
    component: NewLoginComponent,
  },
  {
    path: "public-assessment/:sharingId",
    component: PublicAssessmentComponent,
  },
  {
    path: "sales-space",
    component: SalesSpaceComponent,
    children: [
      { path: "", component: SalesOverviewComponent },
      { path: "sales-overview", component: SalesOverviewComponent },
    ]},

  {
    path: "subscription",
    component: SuccessSignupComponent,
  },
  {
    path: "onetimeredirection",
    component: OneTimeRedirectionComponent,
  },
  {
    path: "print/:id",
    component: PrintPdfComponent,
  },
  {
    path: "best",
    component: BestPracticeComponent,
  },
  {
    path: "mobile",
    component: MobileOverviewComponent,
  },
  {
    path: "homeAdmin",
    component: AdminSpaceComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      { path: "", component: CompaniesOverviewComponent },
      { path: "companies", component: CompaniesOverviewComponent },
      { path: "dashboard", component: DashboardComponent },
      { path: "admin-academy", component: AcademyAdminComponent },
      { path: "project/:screeningId", component: ValidationReviewComponent} ,
      {
        path: "external-companies",
        component: ExternalCompaniesOverviewComponent,
      },
      {
        path: "platform-updates",
        component: PlatformUpdatesComponent,
      },
      {
        path: "Validation-dashboard",
        component: ValidationDashboardComponent,
        children: [
          
        ]
      },
      {
        path: "mastersheet",
        component: MasterSheetComponent,
        children: [
          { path: "databases", component: DatabaseOverviewComponent },
          { path: "databases/:inventoryId", component: GroupsOverviewComponent} ,
          { path: "sub-groups/:groupId", component: SubGroupsOverviewComponent },
          { path: "end-groups/:subGroupId", component: EndGroupsOverviewComponent },
          { path: "processes/:endGroupId", component: ProcessesOverviewComponent },
        ]
      },
      { path: "company/:id", component: ShowCompanyComponent },
      {
        path: "company-assessment-data",
        component: CompanyAssessmentDataComponent,
        children: [
          { path: "databases", component: DatabasesComponent },
          { path: "databases/:inventoryId", component: CompanyAssessmentGroupsComponent} ,
          { path: "sub-groups/:groupId", component: CompanyAssessmentSubGroupsComponent },
          { path: "end-groups/:subGroupId", component: CompanyAssessmentEndGroupsComponent },
          { path: "processes/:endGroupId", component: CompanyAssessmentProcessComponent },
        ]
      },
    ],
  },
  {
    path: "homeServiceProvider/:companyId",
    component: ServiceProviderSpaceComponent,
    canActivate: [AuthGuard, SupplierGuard],
    children: [
      { path: "", component: ScreeningOverviewComponent },
      { path: "components", component: ComponentOverviewComponent },
      { path: "exchange-panel", component: ExchangePanelComponent },
      { path: "warehouses", component: WarehouseOverviewComponent },
      { path: "co2", component: Co2LightComponent },
      { path: "co2-result", component: LightResultComponent },
      { path: "decarbonizer", component: ScreeningOverviewComponent },
      { path: "screening/:screeningId", component: EditScreeningComponent },
      { path: "homeAdmin", component: HomeAdminComponent },
      { path: "compare", component: CompareFunctionComponent },
      { path: "compareProjects", component: CompareProjectsComponent },
      { path: "reflow-academy", component: ReflowAcademyComponent },
      { path: "share", component: ShareOverviewComponent },
      { 
        path: "company-assessment", 
        component: CompanyAssesstmentOverviewComponent,
        children: [
          { path: "", component: AssessmentDashboardComponent },
          { 
            path: ":companyAssesssmentId", 
            component: CalculationStepperComponent,
          },
        ],
      },
    ],
  },
  {
    path: "homeVesselOperator/:companyId",
    component: VesselOperatorSpaceComponent,
    canActivate: [AuthGuard, VesselOwnerGuard],
    children: [
      { path: "", component: VesselsOverviewComponent },
      { path: "components", component: UnassignedComponentsOverviewComponent },
      { path: "vessels", component: VesselsOverviewComponent },
      { path: "vessels/:vesselId", component: ShowVesselComponent },
      { path: "location/:locationId", component: ShowLocationComponent },
      { path: "warehouses", component: WarehouseOverviewComponent },
      { path: "co2", component: Co2LightComponent },
    ],
  },
  {
    path: "homeSales",
    component: SalesSpaceComponent,
    canActivate: [AuthGuard, SalesGuard],
    children: [
      { path: "sales-space-overview", component: SalesOverviewComponent },
    ],
  },
  {
    path: "homeEngineers",
    component: EngineersSpaceComponent,
    canActivate: [AuthGuard, EngineersGuard],
    children: [
      { path: "", component: ValidationDashboardComponent },
      { path: "validation", component: ValidationDashboardComponent },
      { path: "master-sheet", component: MasterSheetComponent },
      { path: "databases", component: DatabaseOverviewComponent },
      { path: "databases/:inventoryId", component: GroupsOverviewComponent} ,
      { path: "sub-groups/:groupId", component: SubGroupsOverviewComponent },
      { path: "end-groups/:subGroupId", component: EndGroupsOverviewComponent },
      { path: "processes/:endGroupId", component: ProcessesOverviewComponent },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
