<div style="background-color: white;">
  <p class="h1-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="h2-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="h3-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="h3-heading-upper">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="h4-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="p-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="p-body2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<p class="p-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

<div class="row">
  <button class="big-turq">
    <label>
      Button 1
    </label>
  </button>
  <button class="big-turq-inactive">
    <label>
      Button 1
    </label>
  </button>
  <button class="big-turq-outline">
    <label>
      Button 1
    </label>
  </button>    
</div>

<div class="row">
  <button class="simple-button">
    <label>
      Button 1
    </label>
  </button>
</div>

<div class="row">
  <div class="screenings">
    <label class="screening-name">Name of screening</label>
    <em class="fa fa-trash screening-delete"></em>
  </div>
</div>


<div class="row">
  <button class="big-blue">
    <label>
      Button 1
    </label>
  </button>
  <button class="big-outline-inactive">
    <label>
      Button 1
    </label>
  </button>
  <button class="big-blue-outline">
    <label>
      Button 1
    </label>
  </button>    
</div>

<div class="row">
  <button class="small-turq">
    <label>
      Button 1
    </label>
  </button>
  <button class="small-inactive">
    <label>
      Button 1
    </label>
  </button>
  <button class="small-turq-outline">
    <label>
      Button 1
    </label>
  </button>
  <button class="small-outline-inactive">
    <label>
      Button 1
    </label>
  </button>    
</div>
<div class="row">
  <div class="btn-group">
    <button type="button" class="btn btn-select" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <label>
        Add new life cycle stage
      </label>
      <em class="icon-arrow-right"></em>
    </button>
    <div class="dropdown-menu select-menu">
      <div *ngFor = "let item of data">
        <label class="dropdown-item select-item" >{{item.name}}</label>
        <div class="dropdown-divider screen-divider"></div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="input-group mb-3 multiple-input">
    <div class="input-group-prepend">
      <span class="input-group-text">Material name</span>
    </div>
    <div class="input-group-prepend">
      <span class="input-group-text"><em class="icon-flag"></em> Some location</span>
    </div>
    <input type="number" placeholder="weight" class="form-control multiple-control" >
    <input type="text" placeholder="Add note" class="form-control multiple-control" >
    <span class="unit-span">kg</span>
    <div class="input-group-append">
      <span class="input-group-text multiple-delete">
        <em class="fa fa-trash"></em>
      </span>
    </div>
  </div>
</div>
<div class="row">
  <div cdkDropList
   #personList = "cdkDropList"
   [cdkDropListData] = "data"
   class = "example-list"
   (cdkDropListDropped) = "onDrop($event)" >
   <div *ngFor = "let item of data; 
      let i = index" class="divlayout" cdkDrag>
      <em class="fa fa-bars order"></em>
      <label>{{item.name}}</label>
      <em class="fa fa-trash delete-order"></em>
   </div >
  </div>
</div>
</div>
