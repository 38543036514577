import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {ClipboardModule} from '@angular/cdk/clipboard';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { BestPracticeComponent } from "./best-practice/best-practice.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminSpaceComponent } from "./admin-space/admin-space.component";
import { SideMenuComponent } from "./admin-space/side-menu/side-menu.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { CompaniesOverviewComponent } from "./admin-space/companies-overview/companies-overview.component";
import { ShowCompanyComponent } from "./admin-space/show-company/show-company.component";
import { AddCompanyComponent } from "./admin-space/companies-overview/add-company/add-company.component";
import { CompanyDetailComponent } from "./admin-space/show-company/company-detail/company-detail.component";
import { DetailUserComponent } from "./admin-space/show-company/detail-user/detail-user.component";
import { AddUserComponent } from "./admin-space/show-company/add-user/add-user.component";
import { ExternalCompaniesOverviewComponent } from "./admin-space/external-companies-overview/external-companies-overview.component";
import { EditExternalComponent } from "./admin-space/external-companies-overview/edit-external/edit-external.component";
import { DetailsExternalComponent } from "./admin-space/external-companies-overview/details-external/details-external.component";
import { EditCompanyComponent } from "./admin-space/show-company/edit-company/edit-company.component";
import { ResetPasswordComponent } from "./admin-space/reset-password/reset-password.component";
import { ProfileImageComponent } from "./admin-space/profile-image/profile-image.component";
import { ServiceProviderSpaceComponent } from "./service-provider-space/service-provider-space.component";
import { VesselOperatorSpaceComponent } from "./vessel-operator-space/vessel-operator-space.component";
import { FilterPipePipe } from "./pipes/filter-pipe.pipe";
import { SortPipe } from "./pipes/sort.pipe";
import { SpSideMenuComponent } from "./service-provider-space/sp-side-menu/sp-side-menu.component";
import { ComponentOverviewComponent } from "./service-provider-space/component-overview/component-overview.component";
import { ComponentEditViewComponent } from "./service-provider-space/component-overview/component-edit-view/component-edit-view.component";
import { AddFileComponent } from "./service-provider-space/component-overview/component-edit-view/add-file/add-file.component";
import { WarehouseOverviewComponent } from "./service-provider-space/warehouse-overview/warehouse-overview.component";
import { EditWarehouseComponent } from "./service-provider-space/warehouse-overview/edit-warehouse/edit-warehouse.component";
import { UpdateStatusComponent } from "./service-provider-space/component-overview/update-status/update-status.component";
import { ComponentDetailsComponent } from "./service-provider-space/component-overview/component-details/component-details.component";
import { ComponentEditCoreComponent } from "./service-provider-space/component-overview/component-details/component-edit-core/component-edit-core.component";
import { ComponentLogComponent } from "./service-provider-space/component-overview/component-details/component-log/component-log.component";
import { ComponentFileLogComponent } from "./service-provider-space/component-overview/component-details/component-file-log/component-file-log.component";
import { ToastrModule } from "ngx-toastr";
import { VesselOperatorOverviewComponent } from "./vessel-operator-space/vessel-operator-overview/vessel-operator-overview.component";
import { VoSideMenuComponent } from "./vessel-operator-space/vo-side-menu/vo-side-menu.component";
import { VesselsOverviewComponent } from "./vessel-operator-space/vessels-overview/vessels-overview.component";
import { EditVesselComponent } from "./vessel-operator-space/vessels-overview/edit-vessel/edit-vessel.component";
import { ShowFileComponent } from "./show-file/show-file.component";
import { ShowVesselComponent } from "./vessel-operator-space/show-vessel/show-vessel.component";
import { EditLocationComponent } from "./vessel-operator-space/show-vessel/edit-location/edit-location.component";
import { ShowLocationComponent } from "./vessel-operator-space/show-vessel/show-location/show-location.component";
import { UnassignedComponentsOverviewComponent } from "./vessel-operator-space/unassigned-components-overview/unassigned-components-overview.component";
import { EditUnassignedComponentComponent } from "./vessel-operator-space/unassigned-components-overview/edit-unassigned-component/edit-unassigned-component.component";
import { LocationDetailsComponent } from "./vessel-operator-space/show-vessel/show-location/location-details/location-details.component";
import { VoUpdateStatusComponent } from "./vessel-operator-space/show-vessel/show-location/vo-update-status/vo-update-status.component";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { AnimatedDigitComponent } from "./best-practice/animated-digit/animated-digit.component";
import { PrintPdfComponent } from "./print-pdf/print-pdf.component";
import { ExchangePanelComponent } from "./service-provider-space/exchange-panel/exchange-panel.component";
import { ComponentPictureComponent } from "./service-provider-space/component-overview/component-details/component-picture/component-picture.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SpComponentFilterComponent } from "./service-provider-space/component-overview/sp-component-filter/sp-component-filter.component";
import { RequestDemoComponent } from "./request-demo/request-demo.component";
import { VoNotifiationComponent } from "./vessel-operator-space/vo-notifiation/vo-notifiation.component";
import { Co2LightComponent } from "./service-provider-space/co2-light/co2-light.component";
import { LightCalculatorComponent } from "./service-provider-space/co2-light/light-calculator/light-calculator.component";
import { LightResultComponent } from "./service-provider-space/co2-light/light-result/light-result.component";
import { VesselSectionComponent } from "./service-provider-space/co2-light/light-calculator/vessel-section/vessel-section.component";
import { ComponentSectionComponent } from "./service-provider-space/co2-light/light-calculator/component-section/component-section.component";
import { ScenarioSectionComponent } from "./service-provider-space/co2-light/light-calculator/scenario-section/scenario-section.component";
import { LightErrorComponent } from "./service-provider-space/co2-light/light-error/light-error.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { MobileOverviewComponent } from './mobile-overview/mobile-overview.component';
import { ScreeningOverviewComponent } from './service-provider-space/screening-overview/screening-overview.component';
import { EditScreeningComponent } from './service-provider-space/screening-overview/edit-screening/edit-screening.component';
import { ScreeningSetupComponent } from './service-provider-space/screening-overview/edit-screening/screening-setup/screening-setup.component';
import { SystemDefinitionComponent } from './service-provider-space/screening-overview/edit-screening/system-definition/system-definition.component';
import { ScreeningInventoryComponent } from './service-provider-space/screening-overview/edit-screening/screening-inventory/screening-inventory.component';
import { ResultSummaryComponent } from './service-provider-space/screening-overview/edit-screening/result-summary/result-summary.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModalComponent } from './service-provider-space/screening-overview/edit-screening/screening-inventory/material-modal/material-modal.component';
import { ScreeningReviewComponent } from './service-provider-space/screening-overview/edit-screening/screening-review/screening-review.component';
import { ChartsModule } from 'ng2-charts';
import { LogoutModalComponent } from './shared/logout-modal/logout-modal.component';
import { ScreeningModalComponent } from './service-provider-space/screening-overview/screening-modal/screening-modal.component';
import { PlatformUpdatesComponent } from './admin-space/platform-updates/platform-updates.component';
import { EditPlatformUpdateComponent } from './admin-space/platform-updates/edit-platform-update/edit-platform-update.component';
import { HomeAdminComponent } from './service-provider-space/home-admin/home-admin.component';
import { CustomerContactComponent } from './service-provider-space/customer-contact/customer-contact.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CompareModalComponent } from './service-provider-space/screening-overview/compare-modal/compare-modal.component';
import { CompareFunctionComponent } from './service-provider-space/screening-overview/compare-function/compare-function.component';
import { FinalStepComponent } from './login/final-step/final-step.component';
import { RefillModalComponent } from './service-provider-space/screening-overview/refill-modal/refill-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SuccessSignupComponent } from './login/success-signup/success-signup.component';
import { OneTimeRedirectionComponent } from './service-provider-space/screening-overview/one-time-redirection/one-time-redirection.component';
import { SignupFormComponent } from './login/signup-form/signup-form.component';
import { DashboardComponent } from './admin-space/dashboard/dashboard.component';
import { LifeCycleModalComponent } from './service-provider-space/screening-overview/edit-screening/system-definition/life-cycle-modal/life-cycle-modal.component';
import { CompareProjectsComponent } from './service-provider-space/compare-projects/compare-projects.component';
import { LifespanModalComponent } from './service-provider-space/screening-overview/edit-screening/screening-inventory/lifespan-modal/lifespan-modal.component';
import { AcademyAdminComponent } from './admin-space/academy-admin/academy-admin.component';
import { ReflowAcademyComponent } from './service-provider-space/reflow-academy/reflow-academy.component';
import { VideosOverviewComponent } from './service-provider-space/reflow-academy/videos-overview/videos-overview.component';
import { VideoPlayComponent } from './service-provider-space/reflow-academy/video-play/video-play.component';
import { PlayListComponent } from './service-provider-space/reflow-academy/play-list/play-list.component';
import { VideoCardComponent } from './service-provider-space/reflow-academy/video-card/video-card.component';
import { MasterSheetComponent } from './admin-space/master-sheet/master-sheet.component';
import { DatabaseOverviewComponent } from './admin-space/master-sheet/database-overview/database-overview.component';
import { EditDatabaseComponent } from './admin-space/master-sheet/database-overview/edit-database/edit-database.component';
import { GroupsOverviewComponent } from './admin-space/master-sheet/groups-overview/groups-overview.component';
import { EditGroupComponent } from './admin-space/master-sheet/groups-overview/edit-group/edit-group.component';
import { SubGroupsOverviewComponent } from './admin-space/master-sheet/sub-groups-overview/sub-groups-overview.component';
import { EditSubGroupComponent } from './admin-space/master-sheet/sub-groups-overview/edit-sub-group/edit-sub-group.component';
import { EndGroupsOverviewComponent } from './admin-space/master-sheet/end-groups-overview/end-groups-overview.component';
import { EditEndGroupComponent } from './admin-space/master-sheet/end-groups-overview/edit-end-group/edit-end-group.component';
import { ProcessesOverviewComponent } from './admin-space/master-sheet/processes-overview/processes-overview.component';
import { EditProcessComponent } from './admin-space/master-sheet/processes-overview/edit-process/edit-process.component';
import { LocationsOverviewComponent } from './admin-space/master-sheet/locations-overview/locations-overview.component';
import { UpsertLocationComponent } from './admin-space/master-sheet/locations-overview/upsert-location/upsert-location.component';
import { EditFolderModalComponent } from './service-provider-space/screening-overview/edit-folder-modal/edit-folder-modal.component';
import { NewLoginComponent } from './new-login/new-login.component';
import { SalesSpaceComponent } from './sales-space/sales-space.component';
import { SalesOverviewComponent } from './sales-space/sales-overview/sales-overview.component';
import { SlSideMenuComponent } from "./sales-space/sl-side-menu/side-menu.component";
import { ValidationDashboardComponent } from './admin-space/validation-dashboard/validation-dashboard.component';
import { ValidationRequestModalComponent } from './service-provider-space/screening-overview/validation-request-modal/validation-request-modal.component';
import { ValidationReviewComponent } from './admin-space/validation-dashboard/validation-review/validation-review.component';
import { ValidationSearchFilterPipe } from './pipes/validation-search-filter.pipe';
import { SubmitCorrectionModalComponent } from './service-provider-space/screening-overview/edit-screening/result-summary/submit-correction-modal/submit-correction-modal.component';
import { ShareOverviewComponent } from './service-provider-space/share-overview/share-overview.component';
import { ProjectComponent } from './service-provider-space/share-overview/project/project.component';
import { MyProjectComponent } from './service-provider-space/share-overview/my-project/my-project.component';
import { SharedWithMeComponent } from './service-provider-space/share-overview/shared-with-me/shared-with-me.component';
import { EngineersSpaceComponent } from './engineers-space/engineers-space.component';
import { EngineersSideMenuComponent } from './engineers-space/engineers-side-menu/engineers-side-menu.component';
import { ShareModalComponent } from './service-provider-space/share-overview/share-modal/share-modal.component';
import { ProjectOverviewComponent } from './service-provider-space/share-overview/project-overview/project-overview.component';
import { MultipleProjectsComponent } from './service-provider-space/share-overview/multiple-projects/multiple-projects.component';
import { SharedNewFolderComponent } from './service-provider-space/share-overview/shared-new-folder/shared-new-folder.component';
import { ProjectDetailsShareComponent } from './service-provider-space/share-overview/project/project-details-share/project-details-share.component';
import { SharedProjectDetailsComponent } from './service-provider-space/share-overview/project-overview/shared-project-details/shared-project-details.component';
import { SharedWithCompaniesComponent } from './service-provider-space/share-overview/project/shared-with-companies/shared-with-companies.component';
import { ImportProjectComponent } from './service-provider-space/share-overview/import-project/import-project.component';
import { UpdateCreditComponent } from './admin-space/companies-overview/update-credit/update-credit.component';
import { SubscriptionModalComponent } from './admin-space/companies-overview/subscription-modal/subscription-modal.component';
import { TransferModalComponent } from './admin-space/companies-overview/transfer-modal/transfer-modal.component';
import { PublicAssessmentComponent } from './service-provider-space/share-overview/public-assessment/public-assessment.component';
import { MoveProjectComponent } from './service-provider-space/screening-overview/move-project/move-project.component';
import { AdjustResourcesComponent } from './admin-space/companies-overview/adjust-resources/adjust-resources.component';
import { RequestDataComponent } from './service-provider-space/share-overview/request-data/request-data.component';
import { RequestProjectOverviewComponent } from './service-provider-space/share-overview/request-project-overview/request-project-overview.component';
import { RequestModalComponent } from './service-provider-space/share-overview/request-modal/request-modal.component';
import { CompanyAssesstmentOverviewComponent } from './service-provider-space/company-assesstment-overview/company-assesstment-overview.component';
import { AssessmentDashboardComponent } from './service-provider-space/company-assesstment-overview/assessment-dashboard/assessment-dashboard.component';
import { CalculationStepperComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/calculation-stepper.component';
import { YearSelectionComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/year-selection/year-selection.component';
import { CompanyProfileComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/company-profile/company-profile.component';
import { DataCollectionComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/data-collection.component';
import { DcProcessModalComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/dc-process-modal/dc-process-modal.component';
import { CompanyAssessmentDataComponent } from './admin-space/company-assessment-data/company-assessment-data.component';
import { DatabasesComponent } from './admin-space/company-assessment-data/databases/databases.component';
import { CompanyAssessmentGroupsComponent } from './admin-space/company-assessment-data/company-assessment-groups/company-assessment-groups.component';
import { CompanyAssessmentSubGroupsComponent } from './admin-space/company-assessment-data/company-assessment-sub-groups/company-assessment-sub-groups.component';
import { CompanyAssessmentEndGroupsComponent } from './admin-space/company-assessment-data/company-assessment-end-groups/company-assessment-end-groups.component';
import { CompanyAssessmentProcessComponent } from './admin-space/company-assessment-data/company-assessment-process/company-assessment-process.component';
import { EditCompanyAssessmentGroupComponent } from './admin-space/company-assessment-data/company-assessment-groups/edit-company-assessment-group/edit-company-assessment-group.component';
import { ResultCompanyAsssessmentComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/result-company-asssessment/result-company-asssessment.component';
import { WarningModalComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/data-collection/warning-modal/warning-modal.component';

import { ProjectPreviewComponent } from './service-provider-space/compare-projects/project-preview/project-preview.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CompanyLoadingComponent } from './service-provider-space/company-assesstment-overview/calculation-stepper/company-loading/company-loading.component';
import { JsonParserPipe } from './pipes/json-parser.pipe';

import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';


const dbConfig: DBConfig  = {
  name: 'reflow-db',
  version: 3,
  objectStoresMeta: [{
    store: 'Screenings',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
    ]
  }, {
    store: 'Folders',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: true } },
    ]
  },],
  
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    BestPracticeComponent,
    AdminSpaceComponent,
    SideMenuComponent,
    TermsConditionComponent,
    CompaniesOverviewComponent,
    ShowCompanyComponent,
    AddCompanyComponent,
    CompanyDetailComponent,
    DetailUserComponent,
    AddUserComponent,
    ExternalCompaniesOverviewComponent,
    EditExternalComponent,
    DetailsExternalComponent,
    EditCompanyComponent,
    ResetPasswordComponent,
    ProfileImageComponent,
    ServiceProviderSpaceComponent,
    VesselOperatorSpaceComponent,
    FilterPipePipe,
    SortPipe,
    SpSideMenuComponent,
    ComponentOverviewComponent,
    ComponentEditViewComponent,
    AddFileComponent,
    WarehouseOverviewComponent,
    EditWarehouseComponent,
    UpdateStatusComponent,
    ComponentDetailsComponent,
    ComponentEditCoreComponent,
    ComponentLogComponent,
    ComponentFileLogComponent,
    ShowFileComponent,
    VesselOperatorOverviewComponent,
    VoSideMenuComponent,
    VesselsOverviewComponent,
    EditVesselComponent,
    ShowVesselComponent,
    EditLocationComponent,
    ShowLocationComponent,
    UnassignedComponentsOverviewComponent,
    EditUnassignedComponentComponent,
    LocationDetailsComponent,
    VoUpdateStatusComponent,
    AnimatedDigitComponent,
    PrintPdfComponent,
    ComponentPictureComponent,
    ExchangePanelComponent,
    SpComponentFilterComponent,
    RequestDemoComponent,
    VoNotifiationComponent,
    Co2LightComponent,
    LightCalculatorComponent,
    LightResultComponent,
    VesselSectionComponent,
    ComponentSectionComponent,
    ScenarioSectionComponent,
    LightErrorComponent,
    MobileOverviewComponent,
    ScreeningOverviewComponent,
    EditScreeningComponent,
    ScreeningSetupComponent,
    SystemDefinitionComponent,
    ScreeningInventoryComponent,
    ResultSummaryComponent,
    MaterialModalComponent,
    ScreeningReviewComponent,
    LogoutModalComponent,
    ScreeningModalComponent,
    PlatformUpdatesComponent,
    EditPlatformUpdateComponent,
    HomeAdminComponent,
    CustomerContactComponent,
    CompareModalComponent,
    CompareFunctionComponent,
    FinalStepComponent,
    RefillModalComponent,
    SuccessSignupComponent,
    OneTimeRedirectionComponent,
    SignupFormComponent,
    DashboardComponent,
    LifeCycleModalComponent,
    CompareProjectsComponent,
    LifespanModalComponent,
    AcademyAdminComponent,
    ReflowAcademyComponent,
    VideosOverviewComponent,
    VideoPlayComponent,
    PlayListComponent,
    VideoCardComponent,
    MasterSheetComponent,
    DatabaseOverviewComponent,
    EditDatabaseComponent,
    GroupsOverviewComponent,
    EditGroupComponent,
    SubGroupsOverviewComponent,
    EditSubGroupComponent,
    EndGroupsOverviewComponent,
    EditEndGroupComponent,
    ProcessesOverviewComponent,
    EditProcessComponent,
    LocationsOverviewComponent,
    UpsertLocationComponent,
    EditFolderModalComponent,
    NewLoginComponent,
    SalesSpaceComponent,
    SalesOverviewComponent,
    SlSideMenuComponent,
    ValidationDashboardComponent,
    ValidationRequestModalComponent,
    ValidationReviewComponent,
    ValidationSearchFilterPipe,
    SubmitCorrectionModalComponent,
    ShareOverviewComponent,
    ProjectComponent,
    MyProjectComponent,
    SharedWithMeComponent,
    EngineersSpaceComponent,
    EngineersSideMenuComponent,
    ShareModalComponent,
    ProjectOverviewComponent,
    MultipleProjectsComponent,
    SharedNewFolderComponent,
    ProjectDetailsShareComponent,
    SharedProjectDetailsComponent,
    SharedWithCompaniesComponent,
    ImportProjectComponent,
    UpdateCreditComponent,
    SubscriptionModalComponent,
    TransferModalComponent,
    PublicAssessmentComponent,
    MoveProjectComponent,
    AdjustResourcesComponent,
    RequestDataComponent,
    RequestProjectOverviewComponent,
    RequestModalComponent,
    CompanyAssesstmentOverviewComponent,
    AssessmentDashboardComponent,
    CalculationStepperComponent,
    YearSelectionComponent,
    CompanyProfileComponent,
    DataCollectionComponent,
    DcProcessModalComponent,
    CompanyAssessmentDataComponent,
    DatabasesComponent,
    CompanyAssessmentGroupsComponent,
    CompanyAssessmentSubGroupsComponent,
    CompanyAssessmentEndGroupsComponent,
    CompanyAssessmentProcessComponent,
    EditCompanyAssessmentGroupComponent,
    ResultCompanyAsssessmentComponent,
    WarningModalComponent,
    RequestModalComponent,
    ProjectPreviewComponent,
    CompanyLoadingComponent,
    JsonParserPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    NgxCaptchaModule,
    DragDropModule,
    NgbModule,
    ChartsModule,
    ClipboardModule,
    NgxQRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [DatePipe,CurrencyPipe],
  bootstrap: [AppComponent],
  entryComponents: [ MaterialModalComponent ]
})
export class AppModule {}
