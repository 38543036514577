import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthGuard } from './auth.guard';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VesselOwnerGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthGuard, private userService: UserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkVesselOwnerAccess();
  }

  checkVesselOwnerAccess(){
    return this.userService.getCurrentUser().pipe(
      map((user:any)=>{
      if (user.type == "Vessel Owner" && this.auth){
        return true;
      } 
      else {
        this.router.navigateByUrl("/login");
        return false;
      }
    }));
  }
}