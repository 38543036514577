import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-admin-space',
  templateUrl: './admin-space.component.html',
  styleUrls: ['./admin-space.component.scss']
})
export class AdminSpaceComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private userService:UserService) { }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(res=>{
      if (res.role != "Super Admin") {
        this.userService.logoutUser(res.userId).subscribe(user=>{
          localStorage.clear();
          this.router.navigate(["/login"]);
        });
      }
      else{
        localStorage.setItem("user_id", res.userId);
        localStorage.setItem("user_role", res.role);
        localStorage.setItem("company_type", res.type);
        localStorage.setItem("company_id", res.companyId);
        localStorage.setItem("permission", res.permission);
        localStorage.setItem("checkedUpdates", res.acceptUpdates);
      }
    },err=>{
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
  }

}
