import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {

  public scrolledDown: boolean = false;
  constructor(private authService: AuthService, private userService: UserService, private router: Router) { }

  ngOnInit(): void {
  }

  accpet() {
    let userId = Number(localStorage.getItem("user_id"))
    this.userService.acceptTerms(userId).subscribe(res => {
      location.reload();
    })
  }

  decline() {
    this.authService.logout();
  }

  scrolled(item) {
    if (item.target.scrollTop > 1080) {
      this.scrolledDown = true;
    }
    else {
      this.scrolledDown = false
    }
  }

}
