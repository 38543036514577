import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public headers(){
    return { 
      headers:  new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        })
    };
  }
}
