import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HelperService } from "./helper.service";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  environmentUrl = "Debug api";
  constructor(
      private http: HttpClient,
      private helperService:HelperService,
      private router: Router, private userService:UserService
    ) {
      this.environmentUrl = environment.apiUrl;
    }  

  logout() {
    this.userService.logoutUser(Number(localStorage.getItem("user_id"))).subscribe(res=>{
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("company_type");
      localStorage.removeItem("company_id");
      localStorage.removeItem("thread");
      this.router.navigate(["/login"]);
      location.reload();
    },
    error=>{
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("company_type");
      localStorage.removeItem("company_id");
      localStorage.removeItem("thread");
      this.router.navigate(["/login"]);
      location.reload();
    }
    );
    
  }

  
  public findAccount(email){
    return this.http.get<any>(
      `${this.environmentUrl}/api/user/forgot-password/${email}`,
      this.helperService.headers()
    );
  }
  public checkResetCode(code){
    return this.http.post<any>(
      `${this.environmentUrl}/api/user/check-code`,
      code,
      { 
        headers:  new HttpHeaders({
            Authorization: "Bearer " + localStorage.getItem("resetToken"),
            "Content-Type": "application/json",
          })
      }
    );
  }
}
