<div class="reflow-form-tabs">
  <div class="form-tabs-header">
    <div class="row">
      <div class="col-sm-4">
        <em class="fa fa-bars" style="margin-top: 10px; margin-left: 25px;" data-toggle="collapse"
          data-target="#target"></em>
        <em class="fa fa-trash-o delete collapse" id="target" (click)="deleteUser()"></em>
      </div>
      <div class="col-sm-4" style="text-align: center; margin-top: 10px;">
        <img src="../../../../assets/favicon.png" alt="" class="user-image" />
        <label class="user-name">{{ userToShow?.fullName }}</label>
        <label class="user-login">{{ userToShow?.lastLogin }}</label>
      </div>
      <div class="col-sm-4">
        <em (click)="showEditView()" class="fa fa-times"
          style="margin-top: 10px; margin-right: 25px; float: right;"></em>
      </div>
    </div>
    <div class="row tabs-menu">
      <div [class]="showLog ? 'col tabs-menu-item-selected' : 'col tabs-menu-item'" (click)="toogleTab()">
        <label class="item-content">Log</label>
      </div>
      <div [class]="
          !showLog ? 'col tabs-menu-item-selected' : 'col tabs-menu-item'
        " (click)="toogleTab()">
        <label class="item-content">Details</label>
      </div>
    </div>
  </div>
  <div class="form-tabs-content">
    <div *ngIf="showLog">
      <div class="row">
        <ul class="timeline">
          <li>
            <label class="timeline-title">Status changed from Transit to user to In supplier's
              warehouse</label>
              <label class="timeline-date">Feb 13, 2019 at 13.34</label>
          </li>
          <li>
            <label class="timeline-title">Status changed from Transit to user to In supplier's
              warehouse</label>
            <label class="timeline-date">Feb 13, 2019 at 13.34</label>
          </li>
          <li>
            <label class="timeline-title">Status changed from Transit to user to In supplier's
              warehouse</label>
            <label class="timeline-date">Feb 13, 2019 at 13.34</label>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="!showLog">
      <form [formGroup]="userToCreate">
        <div class="row">
          <div class="field">
            <input class="re-input" type="email" formControlName="email" required />
            <label class="float-label">Email</label>
            <small *ngIf="submitted && frm.email.errors?.required">*email is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="password" formControlName="password" required />
            <label class="float-label">Password</label>
            <small *ngIf="submitted && frm.password.errors?.required">*password is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <select class="re-input" formControlName="role">
              <option disabled selected value>Role </option>
              <option>Master Admin</option>
              <option>User</option>
            </select>
            <small *ngIf="submitted && frm.role.errors?.required">*role is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="fullName" required />
            <label class="float-label">Full name</label>
            <small *ngIf="submitted && frm.name.errors?.required">*name is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="title" required />
            <label class="float-label">Title</label>
            <small *ngIf="submitted && frm.title.errors?.required">*title is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="department" required />
            <label class="float-label">Department </label>
            <small *ngIf="submitted && frm.department.errors?.required">*department is required</small>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <input class="re-input" type="text" formControlName="phoneNumber" required />
            <label class="float-label">Mobile number</label>
            <small *ngIf="submitted && frm.mobile.errors?.required">*mobile is required</small>
          </div>
        </div>

        <div class="row">

          <div class="field">
            <label class="label">Permission type</label><br>
            <div class="in-radio">
              <input type="radio" id="free" name="gender" value="Free" (change)="changePermission('Free')">
              <label for="free" style="color: black;"> Free</label><br>
              <input type="radio" id="premium" name="gender" value="Premium" (change)="changePermission('Premium')">
              <label for="premium" style="color: black;"> Premium</label><br>
            </div>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="field">
            <input class="re-input" type="number" formControlName="timer" required />
            <label class="float-label">Permission duration</label>
          </div>
        </div>
        <br><br><br><br><br><br>
        <div class="fixed-form-footer">
          <div class="footer-content">
            <button class="btn-solid default">Back</button>
            <button class="btn-solid secondary" (click)="editUser()">
              Done
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>