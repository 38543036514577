import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-best-practice',
  templateUrl: './best-practice.component.html',
  styleUrls: ["./best-practice.component.css"],
})
export class BestPracticeComponent implements OnInit {

  public data : any[];
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.data = [
      {name: 'Thameur',id: 'asedf'}, 
      {name: 'Morten', id: 'aeqww'}, 
      {name: 'Patrick', id: '34534'},
      {name: 'Rasmus', id: '34534'},
      {name: 'Salim', id: '34534'},
      {name: 'maintenance', id: '34534'},
      {name: 'recycling', id: '34534'},
      {name: 'remanufacturing', id: '34534'},
      {name: 'maintenance 1', id: '34534'},
      {name: 'maintenance 2', id: '34534'},
    ]  


    //******************************************* */
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params.id, params.state,JSON.parse(localStorage.getItem('signupValues')));
    });
  }

  private onDropModel(args: any): void {
    const [el, target, source] = args;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
       moveItemInArray(event.container.data, 
          event.previousIndex, event.currentIndex);
    } else {
       transferArrayItem(event.previousContainer.data,
       event.container.data,
       event.previousIndex,
       event.currentIndex);
    }
 }
  
}
