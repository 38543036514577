<div class="reflow-menu">
    <ul class="nav navbar-nav">
        <li class="active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <img src="{{ currentUser?.profilePicture }}" alt="" />
            <label class="sidebar-username" id="user">Hi {{ currentUser?.fullName }}</label
      >
      <em class="fa fa-angle-down expend-icon" id="expend"></em>
    </li>
    <li class="collapse" id="collapseExample" (click)="showResetModal(true)">
      <em class="fa fa-lock"></em>
      <label style="margin-left: 16px !important;">Reset password</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="showImageModal(true)">
            <em class="fa fa-picture-o"></em>
            <label style="margin-left: 16px !important;">Change profile image</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="logOut()">
            <em class="icon-logout"></em>
            <label style="margin-left: 16px !important;">Log out</label>
        </li>
        <li class="collapse" id="collapseExample">
            <a href="javascript:void(0)" data-cb-type="portal">
                <li style="margin-left: 8.3px!important;">
                    <em class="icon-self-service1"></em>
                    <label style="margin-left: 18px !important;">Manage Subscriptions</label>
                </li>
            </a>
        </li>
        <hr class="collapse" id="collapseExample" style="
        background-color: #284e74;
        height: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      " />
    </ul>
    <ul class="nav navbar-nav" *ngIf="false">
        <li (click)="navigateHome()">
            <em class="icon-component"></em>
            <label>Components</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed" *ngIf="userRole=='Master Admin'">
        <li routerLink="homeAdmin">
            <em class="icon-in-warehouse" style="font-size: 24px;"></em>
            <label >Home Admin</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed" *ngIf="false">
        <li routerLink="warehouses">
            <em class="icon-in-warehouse"></em>
            <label >Warehouses</label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed" *ngIf="false">
        <li routerLink="co2">
            <em class="fa fa-leaf"></em>
            <label>CO<sub>2</sub> Calculator <span class="beta-icon">beta</span></label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="decarbonizer">
            <em class="icon-new_quantify"></em>
            <label>Quantify </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="share">
            <em class="icon-new_share active-menu" ></em>
            <label class="active-label" >Share </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="compareProjects">
            <em class="icon-new_compare active-menu"></em>
            <label >Compare </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed" *ngIf="currentUserAccess?.companyAssessmentAccess">
        <li routerLink="company-assessment">
            <em class="icon-company active-menu"></em>
            <label >Company Assessment </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed inactive">
        <li>
            <em class="icon-reduce inactive-menu"></em>
            <label class="inactive-label" >Reduce </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed inactive">
        <li>
            <em class="icon-track inactive-menu" ></em>
            <label class="inactive-label" >Track </label>
        </li>
    </ul>
    <ul class="nav navbar-nav flexed">
        <li routerLink="reflow-academy">
            <em class="icon-new_academy active-menu" ></em>
            <label class="active-label">Academy </label>
        </li>
    </ul>

</div>
<app-reset-password (toogleModal)="showResetModal($event)" [showModal]="showResetView" [userId]="currentUser.id" *ngIf="showResetView">
</app-reset-password>
<app-profile-image (toogleModal)="showImageModal($event)" *ngIf="showImageView"></app-profile-image>
