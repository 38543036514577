import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-detail-user",
  templateUrl: "./detail-user.component.html",
  styleUrls: ["./detail-user.component.css"],
})
export class DetailUserComponent implements OnInit {
  public showLog: boolean = true;
  public permission: string = "";
  userToCreate: FormGroup;
  submitted = false;

  @Output() toogleModal = new EventEmitter();
  @Input() public userToShow?: User = new User();
  @Input() public showForm: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.permission = this.userToShow.permission;
    this.userToCreate = this.formBuilder.group({
      Id: this.userToShow.id,
      email: [this.userToShow.email, Validators.required],
      password: [this.userToShow.password, Validators.required],
      role: [this.userToShow.role, Validators.required],
      lastLogin: this.userToShow.lastLogin,
      acceptedTerms: this.userToShow.acceptedTerms,
      profilePicture: this.userToShow.profilePicture,
      fullName: [this.userToShow.fullName],
      title: [this.userToShow.title],
      department: [this.userToShow.department],
      phoneNumber: [this.userToShow.phoneNumber],
      companyId: this.userToShow.companyId,
      timer: [this.userToShow.timer],
      permission: [this.userToShow.permission],
    });
  }

  public toogleTab(): void {
    this.showLog = !this.showLog;
  }

  public get frm() {
    return this.userToCreate.controls;
  }

  public showEditView() {
    this.toogleModal.emit(false);
  }

  public deleteUser() {
    this.userService.deleteUser(this.userToShow.id).subscribe((res) => {
      this.showEditView();
      this.ngOnInit();
    });
  }

  public editUser() {
    const user: User = new User(this.userToCreate.value);
    user.id = this.userToShow.id;
    this.userService.editUser(user).subscribe((res) => {
      this.showEditView();
      this.ngOnInit();
    });
  }
  public changePermission(value) {
    this.userToCreate.controls["permission"].setValue(value);
  }
}
