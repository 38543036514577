<div class="reflow-menu">
    <ul class="nav navbar-nav">
        <li class="active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <img src="{{ currentUser.profilePicture }}" alt="" />
            <label class="sidebar-username" id="user">Hi {{ currentUser?.fullName }}</label
      >
      <em class="fa fa-angle-down expend-icon" id="expend"></em>
    </li>
    <li class="collapse" id="collapseExample" (click)="showResetModal(true)">
      <em class="fa fa-lock"></em>
      <label>Reset password</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="showImageModal(true)">
            <em class="fa fa-picture-o"></em>
            <label>Change profile image</label>
        </li>
        <li class="collapse" id="collapseExample" (click)="logOut()">
            <em class="fa fa-sign-out"></em>
            <label>Log out</label>
        </li>
        <hr class="collapse" id="collapseExample" style="
        background-color: #284e74;
        height: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      " />
    </ul>
    <ul class="nav navbar-nav">
        <li (click)="navigateHome()">
            <em class="fa fa-ship"></em>
            <label>Fleet</label>
        </li>
        <li routerLink="components">
            <img class="icon-image" src="../../../assets/component.png" alt="" />
            <label>Unassigned Components</label>
        </li>
    </ul>
    <ul class="nav navbar-nav">
        <li routerLink="warehouses">
            <em class="fa fa-building"></em>
            <label>Warehouses</label>
        </li>
    </ul>
    <ul class="nav navbar-nav">
        <li routerLink="co2calculator">
            <em class="fa fa-leaf"></em>
            <label>CO<sub>2</sub> Calculator</label>
        </li>
    </ul>
</div>
<app-reset-password (toogleModal)="showResetModal($event)" [showModal]="showResetView" [userId]="currentUser.id" *ngIf="showResetView">
</app-reset-password>
<app-profile-image (toogleModal)="showImageModal($event)" *ngIf="showImageView"></app-profile-image>