import { Component, OnChanges, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { ScreeningService } from 'src/app/services/screening.service';
import { LifeCycle } from 'src/app/models/life-cycle.model';
import { Screening, ScreeningLog } from 'src/app/models/screening.model';
import { UserService } from 'src/app/services/user.service';
import { Guid } from 'guid-typescript';
import { element } from 'protractor';
import { NgxIndexedDBService } from 'ngx-indexed-db';


@Component({
  selector: 'app-system-definition',
  templateUrl: './system-definition.component.html',
  styleUrls: ['./system-definition.component.scss']
})
export class SystemDefinitionComponent implements OnInit, OnChanges {
  @Output() public close = new EventEmitter();
  public data: any[] = [];
  public data1: any[] = [];
  public selectedActivity: string = "Specify activity";
  public activityToPush: string = "";
  public dataToSelect: any[] = [];
  public preDefinedSystems: any[] = [];
  public listOfActivity: any[] = [];
  public selectedItems: LifeCycle[];
  public showAlert = false;
  public showTimeAlert = false;
  public loadData = false;
  public screening: Screening;
  public screeningId: number;
  public lifeCycle = false;
  public hasScope = false;
  public showInclude = false;
  public stage: any;
  public timeSpan: any;
  public disable = true;
  public showtimespaninputs = false;
  public showincludescopes = true;
  public showbasedtimecalculation = false;
  public selectedduration = "Year";

  constructor(
    private activatedRoute: ActivatedRoute, 
    private screeningService: ScreeningService, 
    private userService: UserService,
    private dbService: NgxIndexedDBService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.screeningId = Number(params["screeningId"]);
      this.listOfActivity = [
        {
          stage: 'Internal activity',
          lifeSpan: 0,
          info: true,
          desc: "Activities owned or controlled by the company performing the assessment. e.g. a company manufacturing a coffee machine, will be accounted for the manufacturing stage as they are the ones producing the product. Therefore they should select manufacturing as internal activity.",

        },
        {
          stage: 'External activity',
          lifeSpan: 0,
          info: true,
          desc: "Activities managed by other companies or users e.g. the company manufacturing a coffee machine will select external for the usage stage, as they are not the ones using the product.",

        },
      ];
      this.preDefinedSystems = [
        {
          stage: 'Cradle-to-gate',
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "Assessment of a partial product life cycle from resource extraction (cradle) to the factory gate (i.e., before it is transported to the consumer)",
          includes: "Manufacturing",
        },
        {
          stage: 'Cradle-to-grave',
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "This approach takes into account the entire life-cycle of materials and associated systems from raw material acquisition to production, use, and disposal.",
          includes: "Manufacturing, distribution, use, distribution, disposal",
        },
        {
          stage: 'Cradle-to-cradle',
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "This approach aims to minimize the negative effects of the product production, from raw material extraction to recycling of the used product for new use.",
          includes: "Manufacturing, distribution, use, distribution, recycling",
        },
      ];
      this.data = [
        {
          stage: 'Manufacturing',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Manufacturing stage involves all activities and use of materials, energy, machinery, and equipment related to the transformation of resources into a final product.",
          modeling: "It counts the emissions related to the transportation of a product, including the direct emissions from fuel combustion and embedded emissions originating from upstream activities.",
          tips: [
            "Tip: This stage is usually the first step of a cradle-to-grave LCA."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Distribution',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Distribution stage involves all transportation of a product between the factory gate and the distribution centers or end users.",
          modeling: "It counts the emissions related to the transportation of a product, including the direct emissions from fuel combustion and embedded emissions originating from upstream activities.",
          tips: [
            "Tip: This stage refers to the distribution of the product itself rather than the transportation of process inputs of other life cycle stages (e.g., materials).",
            "It can be used when the distribution of product takes place, usually post the Manufacturing, Remanufacturing, Refurbishment, and Reconditioning stages."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Usage',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Usage stage involves all activities related to the use of the product by the consumer (end-user).",
          modeling: "It counts direct emissions from fuel consumption taking place during the product’s use and indirect emissions originating from upstream activities of the consumed energy.",
          tips: [
            "The Maintenance stage involves all activities and use of materials, energy, machinery, and equipment necessary to maintain the product’s performance and ensure its lifespan."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Maintenance',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Maintenance stage involves all activities and use of materials, energy, machinery, and equipment necessary to maintain the product’s performance and ensure its lifespan.",
          modeling: "It counts the emissions generated during the maintenance phase and embodied emissions in process inputs and outputs originating from upstream or downstream activities.",
          tips: [
            "Tip: Add this stage when resources and energy are used for the maintenance of your product."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Installation',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Installation Stage involves all activities and use of materials, energy, machinery, and equipment necessary to install the product for its finalized product stage. This might only be applicable to certain industries where the product needs either installation or assembly at another location than the Manufacturing Stage.",
          modeling: "The Installation Stage counts the emissions generated during the installation phase and the embodied emissions in process input and outputs generated from upstream and downstream activities.",
          tips: [
            "Tip: Add this stage when resources and energy are used for the Installation of your product."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Remanufacturing',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: true, desc: "Remanufacturing is the repair or replacement of worn-out or obsolete components/parts of a product so the product is returned to its original condition (as new) or improved beyond its original condition.",
          modeling: "It counts the emissions generated during the remanufacturing phase and embodied emissions in process inputs and outputs originating from upstream or downstream activities. The inclusion of this stage in the assessment will automatically double the product’s lifespan.",
          tips: [
            "Tip: This stage should be included only when the product is expected to subsequently return to its initial condition and have a lengthened lifespan. This stage should not be confused with Maintenance, Refurbishment, and Reconditioning stages."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Refurbishment',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: true,
          desc: "Refurbishment is the repair or replacement of worn-out or obsolete components/parts of a product, specifications update, or cosmetic improvements so the product is returned into good working order.",
          modeling: "This stage counts the direct emissions generated during the refurbishment phase and embodied emissions in process inputs and outputs originating from upstream or downstream activities. Inclusion of this stage in the assessment automatically assumes a lifespan expansion which is defined by the user.",
          tips: [
            "Tip: Unlike Remanufacturing, Refurbishment extends the product’s lifespan by less than the initial lifespan."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Reconditioning',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: true,
          desc: "Reconditioning is the repair or replacement of damaged or faulty components/parts of a product so the product is returned to a good operational condition.",
          modeling: "It counts the emissions generated during the reconditioning phase and embodied emissions in process inputs and outputs originating from upstream or downstream activities. Inclusion of this stage in the assessment automatically assumes a lifespan expansion which is defined by the user.",
          tips: [
            "Tip: Unlike Remanufacturing, Refurbishment extends the product’s lifespan by less than the initial lifespan."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Recycling',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: true,
          desc: "Recycling includes all processes of converting waste materials into new reusable materials. In terms of product, recycling includes the product’s transformation into its basic materials and reprocessing them into new materials.",
          modeling: "It counts the emissions generated during the recycling of wastes and embodied emissions in the process inputs and outputs originating from upstream or downstream activities.",
          tips: [
            "Tip: This stage is used to model the recycling of all recyclable wastes regardless of their source stages."
          ],
          activity: "Specify activity"
        },
        {
          stage: 'Disposal',
          screeningId: this.screeningId,
          lifeSpan: 0,
          info: true,
          circular: false,
          desc: "The Disposal stage involves all processes by which a product, or part of it, is disposed of, stored, or destroyed after its usefulness has ceased.",
          modeling: "It counts the direct emissions generated and embodied emissions in energy and materials consumed during the disposal phase.",
          tips: [
            "Tip: This stage is relevant to the disposal of the product itself and not the disposal of waste produced in other stages (e.g., Manufacturing, Maintenance, and etc.)"
          ],
          activity: "Specify activity"
        },
      ];
      this.dataToSelect = Object.assign([],this.data);
      this.screeningService.updateState(this.screeningId, "system-definition").subscribe(res => {
        this.screeningService.getScreeningById(this.screeningId).subscribe(res => {
          this.hasScope=res.includeScopes;
          this.showbasedtimecalculation=res.timeBased;
          if(res.timeBased==true){
            this.selectedduration=res.timeSpanUnit;
            this.timeSpan=res.timeSpan;
          }
          this.showtimespaninputs=res.timeBased;
          this.screening = res;
          this.disable = this.screening['validated'];
          this.selectedItems = res.lifeCycles;
          if(this.selectedItems.length>0){
            this.showInclude=true;
          }
          if (this.selectedItems.filter(x => x.stage == "Manufacturing").length > 0) {
            var index = this.dataToSelect.map(function (e) { return e.stage; }).indexOf('Manufacturing');
            this.dataToSelect.splice(index, 1);
          }
          if (this.selectedItems.filter(x => x.stage == "Recycling").length > 0) {
            var index = this.dataToSelect.map(function (e) { return e.stage; }).indexOf('Recycling');
            this.dataToSelect.splice(index, 1);
          }
          if (this.selectedItems.filter(x => x.stage == "Disposal").length > 0) {
            var index = this.dataToSelect.map(function (e) { return e.stage; }).indexOf('Disposal');
            this.dataToSelect.splice(index, 1);
          }
        });
      });
    });
    this.selectedItems = [];
  }

  ngOnChanges() {

  }


  private onDropModel(args: any): void {
    const [el, target, source] = args;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onClose(): void {
    this.lifeCycle = false;
  }

  public showLifeCycleModal(isShow: boolean) {
    this.lifeCycle = isShow;
  }

  public closeLifeCycleModal(event) {
    this.lifeCycle = false;
    if (event != null) {
      this.selectedItems[this.selectedItems.length - 1].lifeSpan = event;
    }
  }

  public addToselectedList(index) {
    this.showInclude=true;
    this.selectedItems.push(this.dataToSelect[index]);
    if (this.dataToSelect[index].stage == "Manufacturing" || this.dataToSelect[index].stage == "Recycling" || this.dataToSelect[index].stage == "Disposal") {
      this.dataToSelect.splice(index, 1);
    }
    this.stage = this.dataToSelect[index].stage;
    if (this.stage == "Refurbishment" || this.stage == "Reconditioning") {
      this.showLifeCycleModal(true);
    }
    let lifeCycleToAdd = this.selectedItems[this.selectedItems.length-1];
    lifeCycleToAdd.id=Math.floor(Math.random()*110000);
    this.selectedItems[this.selectedItems.length-1] = Object.assign({},lifeCycleToAdd);
  }

  public removeToselectedList(index) {
    if (this.disable) return
    var itemToremove = this.selectedItems[index];
    this.selectedItems.splice(index, 1);
    if (itemToremove.stage == "Manufacturing" || itemToremove.stage == "Recycling" || itemToremove.stage == "Disposal") {
      if (itemToremove.stage == "Manufacturing" && this.dataToSelect.filter(x=>x.stage=="Manufacturing").length==0) {
        this.dataToSelect.push({ stage: itemToremove.stage, screeningId: this.screeningId, info: true, circular: false, desc: "The manufacturing stage involves all the activities related to the transformation of raw materials into a finished product. Manufacturing accounts for all the materials, energy/fuel, components and/or electronic components used to achieve a product's realization, including the waste material during the manufacturing process." });
      }
      else if (itemToremove.stage == "Recycling" && this.dataToSelect.filter(x=>x.stage=="Recycling").length==0) {
        this.dataToSelect.push({ stage: itemToremove.stage, screeningId: this.screeningId, info: true, circular: true, desc: "Recycling is the process of transforming a product into its basic materials for them to be used as new materials. The recyclability of a material depends on its ability to reacquire the properties it had as a virgin state. The recycling process accounts for the avoided production of new materials. In a circular economy, recycling is the last option." });
      }
      else if (itemToremove.stage == "Disposal" && this.dataToSelect.filter(x=>x.stage=="Disposal").length==0) {
        this.dataToSelect.push({ stage: itemToremove.stage, screeningId: this.screeningId, info: true, circular: false, desc: "The disposal stages involve removing and destroying or storing damaged, used, or other unwanted products and substances. Disposal includes burning, burial at landfill sites or at sea. This stage includes all relevant usage of materials, energy and waste materials generated to dispose of a product or system." });
      }
    }
    if(this.selectedItems.length==0){
      this.showInclude=false;
      this.hasScope= false;
    }
  }

  public next() {
    let convertedTime =0;
    switch(this.selectedduration){
      case "Year":
        convertedTime=Number(this.screening.product.lifeSpan);
        break;
      case "Month": 
        convertedTime=Number(this.screening.product.lifeSpan)*12;
        break;
      case "Day":
        convertedTime=Number(this.screening.product.lifeSpan)*365; 
        break;
      case "Hour":
        convertedTime=Number(this.screening.product.lifeSpan)*8760; 
        break;
    }
    if (this.selectedItems.length == 0) {
      this.showAlert = true;
      return;
    }
    else if (Number(this.timeSpan) > convertedTime) {
      this.showTimeAlert = true;
      return;
    }
    else {
      this.screeningService.updateScreeningLifeCycle(this.screeningId, this.selectedItems).subscribe(res => {
        this.screeningService.updateScopeState({screeningId:this.screeningId, scopeState:this.hasScope,timeRangeUnit:this.selectedduration,timeRange:this.timeSpan, timebased:this.showbasedtimecalculation}).subscribe(scopeState=>{
          this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<any>) => {
            let mergeArray=indexedDBResut[0].filter(x=>x.id!=res.id);
            mergeArray.unshift(res);
            this.dbService.clear('Screenings').subscribe((status) => {
              this.dbService.add('Screenings', mergeArray)
                .subscribe((key) => {
                });
            });
          },
          error => {
              console.log(error);
          });
          this.close.emit("next");
          this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user => {
            let screeningLog = { date: new Date(), description: "Update System definition", screeningId: this.screeningId, userName: user.fullName };
            this.screeningService.createScreeningLog(screeningLog).subscribe(resLog => {
            });
          });
        });
      });
    }
  }

  public save() {
    this.screeningService.updateScreeningLifeCycle(this.screeningId, this.selectedItems).subscribe(res => {
      this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<any>) => {
        let mergeArray=indexedDBResut[0].filter(x=>x.id!=res.id);
        mergeArray.unshift(res);
        this.dbService.clear('Screenings').subscribe((status) => {
          this.dbService.add('Screenings', mergeArray)
            .subscribe((key) => {
            });
        });
      },
      error => {
          console.log(error);
      });
      this.close.emit("close");
    });
  }

  public previous() {
    this.screeningService.updateScreeningLifeCycle(this.screeningId, this.selectedItems).subscribe(res => {
      this.close.emit("previous");
    });
  }

  public generateLifeCycles(preDefinedSystem: any) {
    for(let i=0; i<this.data.length; i++){
      this.data[i].activity="Specify activity";
    }
    this.selectedItems = Object.assign([]);
    this.dataToSelect = [...this.data];
    this.dataToSelect.splice(0,1);
    if (preDefinedSystem.stage == "Cradle-to-gate") {
      this.selectedItems.push(this.data[0]);
      this.showInclude=true;
    }
    else if (preDefinedSystem.stage == "Cradle-to-grave") {
      this.selectedItems.push(this.data[0]);
      this.selectedItems.push(this.data[1]);
      this.selectedItems.push(this.data[2]);
      this.selectedItems.push(this.data[1]);
      this.selectedItems.push(this.data[9]);
      this.showInclude=true;
    }
    else if (preDefinedSystem.stage == "Cradle-to-cradle") {
      this.selectedItems.push(this.data[0]);
      this.selectedItems.push(this.data[1]);
      this.selectedItems.push(this.data[2]);
      this.selectedItems.push(this.data[1]);
      this.selectedItems.push(this.data[8]);
      this.showInclude=true;
    }
  }

  public ScopeToogle(target:any):void{
    console.log(this.selectedItems);
    this.hasScope=!this.hasScope;
  }

  public updateLifeCycleActivity(activity:any, lifecycle:any,index:any):void{
    const _lifecycle = {...lifecycle};
    _lifecycle.activity=activity;
    this.selectedItems[index]=Object.assign({},_lifecycle);
  }
  public showTimeSpanInputs(){
    if(this.showbasedtimecalculation==true){
      this.showtimespaninputs = true;
    }
  }
  // public showincludescopes = false;
  // public showbasedtimecalculation = false;
  public showIncludeScopes ()
  {
    if(this.showincludescopes == true)
    {
      this.showincludescopes = false;
    }else{
      this.showincludescopes = true;
    }
  }

  public showBasedTimeCalculation ()
  {
    if(this.showbasedtimecalculation == true)
    {
      this.showbasedtimecalculation = false;
    }else{
      this.showbasedtimecalculation = true;
    }
  }
  public changeSelectDuration(valueselected)
  {
    this.selectedduration = valueselected; 
  }
}
